import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGroupList() { 
  // Use toast
  const toast = useToast()

  const refGroupListTable = ref(null)  

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: false , },
    { key: 'amountAssets', sortable: false},
    { key: 'actions' },
  ]

  const perPage = ref(10)
  const totalGroup = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refGroupListTable.value ? refGroupListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGroup.value,
    }
  })

  const refetchData = () => {
    refGroupListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchAllGroup = (ctx, callback) => {
    console.log(`CHAMOU fetchAllGroup`)

    store
      .dispatch('app-management/fetchAllGroup', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        
      })
      .then(response => {
        const group = response.data.data;
        const total = response.data.length;
        callback(group)
        totalGroup.value = response.data.totalItems
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })




  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  return {
    fetchAllGroup,
    tableColumns,
    perPage,
    currentPage,
    totalGroup,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refGroupListTable,
    refetchData,

  }
}
